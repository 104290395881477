(function($) {
    $.fn.requestLabel   = function(options) {
        var form_action = 'label';
        var form_key    = 'create';

        var settings    = $.extend({
            url: '',
            first_name: '',
            surname: '',
            street: '',
            street_number: '',
            postcode: '',
            city: '',
            country: '',
            label_type: '',
            success: null,
            error: null
        }, options);

        var postData    = {};
        postData[form_action]       = form_key;
        postData['first_name']      = settings.first_name;
        postData['surname']         = settings.surname;
        postData['street']          = settings.street;
        postData['street_number']   = settings.street_number;
        postData['postcode']        = settings.postcode;
        postData['city']            = settings.city;
        postData['country']         = settings.country;
        postData['label_type']      = settings.label_type;

        $.ajax({
            type: 'POST',
            url: settings.url,
            dataType: 'json',
            data: postData,
            success: function(response, status) {
                if(typeof settings.success === 'function') {
                    return settings.success(response, status);
                }
            },
            error: function(response, status) {
                if(typeof settings.error === 'function') {
                    return settings.error(response, status);
                }
            }
        });
    };
})(jQuery);


jQuery(document).ready(function($) {
    $('form#dhl-label').submit(function(event) {
        event.preventDefault();

        var form    = $(this).closest('form');

        // do not resend form if already done
        if(($(form).hasClass('was-validated')) && ($(form).hasClass('is-valid'))) {
            return;
        }

        $(form).removeClass('is-valid').removeClass('is-invalid');
        $('.form-message').removeClass('show');

        if ($(form)[0].checkValidity() === false) {
            $(form).addClass('is-invalid');
        } else {
            $(form).addClass('is-valid');
        }

        $(form).addClass('was-validated');

        if((!$(form).hasClass('was-validated')) || ($(form).hasClass('is-invalid'))) {
            return;
        }

        var options = {
            url: $(form).attr('action'),
            first_name: $(form).find('input[name="first_name"]').val(),
            surname: $(form).find('input[name="surname"]').val(),
            street: $(form).find('input[name="street"]').val(),
            street_number: $(form).find('input[name="street_number"]').val(),
            postcode: $(form).find('input[name="postcode"]').val(),
            city: $(form).find('input[name="city"]').val(),
            country: $(form).find('select[name="country"] option:selected').val()
        };

        options['success']  = function(response, status) {
            if(response.status !== true) {
                $('.form-message').addClass('show');
                $('.form-message .response-text').text(response.message[0]);
                $(form).removeClass('was-validated').removeClass('is-valid');

                return;
            }

            $(form).find('.pdf-label-create').addClass('hide');
            $(form).find('.pdf-label-download').addClass('show');

            $(form).find('.pdf-label-download').attr('href', response.data.file).attr('data-download', 'accept');
        };

        $(this).requestLabel(options);
    });


    $('form#dhl-label-it').submit(function(event) {
        event.preventDefault();

        var form    = $(this).closest('form');

        // do not resend form if already done
        if(($(form).hasClass('was-validated')) && ($(form).hasClass('is-valid'))) {
            return;
        }

        $(form).removeClass('is-valid').removeClass('is-invalid');
        $('.form-message').removeClass('show');

        if ($(form)[0].checkValidity() === false) {
            $(form).addClass('is-invalid');
        } else {
            $(form).addClass('is-valid');
        }

        $(form).addClass('was-validated');

        if((!$(form).hasClass('was-validated')) || ($(form).hasClass('is-invalid'))) {
            return;
        }

        var options = {
            url: $(form).attr('action'),
            first_name: $(form).find('input[name="first_name"]').val(),
            street: $(form).find('input[name="street"]').val(),
            street_number: $(form).find('input[name="street_number"]').val(),
            postcode: $(form).find('input[name="postcode"]').val(),
            city: $(form).find('input[name="city"]').val(),
            label_type: $(form).find('input[name="label_type"]:hidden').val()
        };

        $(form).find('.pdf-label-download').removeClass('show');
        $(form).find('.pdf-label-download').attr('href', '#').attr('data-download', 'reject');
        $(form).removeClass('was-validated').removeClass('is-valid');

        options['success']  = function(response, status) {
            if(response.status !== true) {
                $('.form-message').addClass('show');
                $('.form-message .response-text').text(response.message[0]);

                return;
            }

            $(form).find('.pdf-label-download').addClass('show');
            $(form).find('.pdf-label-download').attr('href', response.data.file).attr('data-download', 'accept');
        };

        $(this).requestLabel(options);
    });


    /**
     * label download button
     */
    $('form#dhl-label .pdf-label-download').click(function(event) {
        if($(this).attr('data-download')=='reject') {
            event.preventDefault();
            return;
        }
    });


    /**
     * language switch
     */
    $('a[data-toggle="tab"]').on('shown.bs.tab', function (e) {
        var lang    = $(e.target).attr('aria-controls');

        if(lang === 'text-fr') {
            var el  = $('form#dhl-label [data-lang-fr], .form-message [data-lang-fr]').each(function(index, val) {
               $(this).text($(this).attr('data-lang-fr'));
            });
        }

        if(lang === 'text-de') {
            var el  = $('form#dhl-label [data-lang-de], .form-message [data-lang-de]').each(function(index, val) {
                $(this).text($(this).attr('data-lang-de'));
            });
        }
    })
});
